<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" :inline="true" label-position="top">
      <el-card class="mb-16">
        <el-row>
          <el-form-item required label="專案">
            <el-select
              v-model="form.project"
              filterable
              :loading="loadingProject"
              @change="afterProjectChange"
            >
              <el-option
                v-for="item in projectList"
                :key="item._id"
                :label="item.project_id"
                :value="item._id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="formType === 'edit'" required label="儀器">
            <el-select
              v-model="selectedInstrument"
              :loading="loadingInstrumentList"
              :disabled="!instrumentList[form.project]"
              @change="resetInstrumentVersion"
              filterable
            >
              <el-option
                v-for="instrument in instrumentList[form.project]"
                :key="instrument._id"
                :label="instrument.data[instrument.data.length - 1].name"
                :value="instrument._id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="formType === 'edit' && selectedInstrument && selectedInstrumentDetails.data"
            required
            label="選擇版本"
          >
            <el-select
              v-model="selectedInstrumentVersion"
              :disabled="!selectedInstrument"
              @change="changeInstrumentVersion"
              filterable
              default-first-option
            >
              <el-option
                v-for="version in selectedInstrumentDetails.data"
                :key="version._id"
                :label="$moment(version.created_at).format('YYYY/MM/DD HH:mm')"
                :value="version._id"
              />
            </el-select>
          </el-form-item>
        </el-row>
      </el-card>
      <el-card class="mb-16" v-if="showInstrumentDetailsForm">
        <el-row>
          <el-form-item required label="檔案">
            <el-select
              v-model="form.data.document_id"
              :loading="loadingDocument"
              @change="handleDocumentChange"
              filterable
            >
              <el-option
                v-for="item in documentList"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              />
            </el-select>
          </el-form-item>
          <el-form-item required label="綁定值">
            <el-select
              v-model="form.data.reference"
              :disabled="!form.data.document_id"
              :loading="loadingDataIndex"
              filterable
            >
              <el-option
                v-for="item in dataIndexList"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="綁定電量">
            <el-select
              v-model="form.data.bind_batt"
              :key="componentKey"
              :loading="loadingInstrumentList"
              :disabled="!instrumentList[form.project]"
              @change="changeBind"
              filterable
              clearable
            >
              <el-option
                v-for="instrument in instrumentList[form.project]"
                :key="instrument._id"
                :label="instrument.data[instrument.data.length - 1].name"
                :value="instrument._id"
              />
            </el-select>
          </el-form-item>
        </el-row>
        <template>
          <el-row>
            <el-form-item required label="Name">
              <el-input v-model="form.data.name" />
            </el-form-item>
            <el-form-item label="Unit">
              <el-input v-model="form.data.unit" />
            </el-form-item>

            <el-form-item label="Lat">
              <el-input v-model="form.data.coordinates[1]" />
            </el-form-item>
            <el-form-item label="Lng">
              <el-input v-model="form.data.coordinates[0]" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Initial Value(R0)">
              <el-input v-model="form.data.R0" />
            </el-form-item>
            <el-form-item label="Initial Temp (T0)">
              <el-input v-model="form.data.T0" />
            </el-form-item>
            <el-form-item label="Temperature Factor (K)">
              <el-input v-model="form.data.K" />
            </el-form-item>
            <el-form-item label="Offset">
              <el-input v-model="form.data.offset" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Factor(F0)">
              <el-input v-model="form.data.F0" />
            </el-form-item>
            <el-form-item label="Factor(F1)">
              <el-input v-model="form.data.F1" />
            </el-form-item>

            <el-form-item label="Factor(F2)">
              <el-input v-model="form.data.F2" />
            </el-form-item>
            <el-form-item label="Factor(F3)">
              <el-input v-model="form.data.F3" />
            </el-form-item>

            <el-form-item label="Factor(F4)">
              <el-input v-model="form.data.F4" />
            </el-form-item>

            <el-form-item label="Factor(F5)">
              <el-input v-model="form.data.F5" />
            </el-form-item>
          </el-row>
          <!--警戒值設定欄-->
          <el-row>
            <el-form-item label="WL (+)">
              <el-input v-model="form.data.WL_plus" />
            </el-form-item>

            <el-form-item label="WL (-)">
              <el-input v-model="form.data.WL_minus" />
            </el-form-item>

            <el-form-item label="AL (+)">
              <el-input v-model="form.data.AL_plus" />
            </el-form-item>

            <el-form-item label="AL (-)">
              <el-input v-model="form.data.AL_minus" />
            </el-form-item>

            <el-form-item label="WSL (+)">
              <el-input v-model="form.data.WSL_plus" />
            </el-form-item>

            <el-form-item label="WSL (-)">
              <el-input v-model="form.data.WSL_minus" />
            </el-form-item>
          </el-row>
          <!--1小時速率警戒值設定欄-->
          <el-row>
            <el-form-item label="Rate_1Hr_WL (+)">
              <el-input v-model="form.data.rate_1Hr_config.WL_plus" />
            </el-form-item>

            <el-form-item label="Rate_1Hr_WL (-)">
              <el-input v-model="form.data.rate_1Hr_config.WL_minus" />
            </el-form-item>

            <el-form-item label="Rate_1Hr_AL (+)">
              <el-input v-model="form.data.rate_1Hr_config.AL_plus" />
            </el-form-item>

            <el-form-item label="Rate_1Hr_AL (-)">
              <el-input v-model="form.data.rate_1Hr_config.AL_minus" />
            </el-form-item>

            <el-form-item label="Rate_1Hr_WSL (+)">
              <el-input v-model="form.data.rate_1Hr_config.WSL_plus" />
            </el-form-item>

            <el-form-item label="Rate_1Hr_WSL (-)">
              <el-input v-model="form.data.rate_1Hr_config.WSL_minus" />
            </el-form-item>
          </el-row>
          <!--24小時速率警戒值設定欄-->
          <el-row>
            <el-form-item label="Rate_24Hr_WL (+)">
              <el-input v-model="form.data.rate_24Hr_config.WL_plus" />
            </el-form-item>

            <el-form-item label="Rate_24Hr_WL (-)">
              <el-input v-model="form.data.rate_24Hr_config.WL_minus" />
            </el-form-item>

            <el-form-item label="Rate_24Hr_AL (+)">
              <el-input v-model="form.data.rate_24Hr_config.AL_plus" />
            </el-form-item>

            <el-form-item label="Rate_24Hr_AL (-)">
              <el-input v-model="form.data.rate_24Hr_config.AL_minus" />
            </el-form-item>

            <el-form-item label="Rate_24Hr_WSL (+)">
              <el-input v-model="form.data.rate_24Hr_config.WSL_plus" />
            </el-form-item>

            <el-form-item label="Rate_24Hr_WSL (-)">
              <el-input v-model="form.data.rate_24Hr_config.WSL_minus" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="儀器故障警報計數基準">
              <el-input v-model="form.data.down_count_base" />
            </el-form-item>
            <el-form-item label="儀器故障警報計數上限">
              <el-input v-model="form.data.down_count_limit" />
            </el-form-item>
            <el-form-item label="警戒值警報計數上限">
              <el-input v-model="form.data.alert_count_limit" />
            </el-form-item>
            <el-form-item label="速率警戒值警報計數上限">
              <el-input v-model="form.data.rate_alert_count_limit" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="類別">
              <el-input v-model="form.data.class" />
            </el-form-item>
            <el-form-item label="台鐵儀器編號">
              <el-input v-model="form.data.TRA_no" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Start Date">
              <el-date-picker
                v-model="form.data.startDate"
                type="datetime"
                format="yyyy/MM/dd HH:mm"
              />
              <p v-if="firstDataTimestamp">
                最早資料：{{ $moment(firstDataTimestamp).format('YYYY/MM/DD HH:mm') }}
              </p>
            </el-form-item>
            <el-form-item label="Decimal points">
              <el-input-number v-model="form.data.decimal_points" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="Display Output">
              <el-select v-model="form.display_output" filterable default-first-option>
                <el-option label="Reading 1" value="reading1" />
                <el-option label="Reading 2" value="reading2" />
              </el-select>
            </el-form-item>
            <el-form-item prop="chart_type" label="Chart Type">
              <el-select v-model="form.chart_type" clearable>
                <el-option
                  v-for="type in chartTypeList"
                  :key="type.label"
                  :label="type.label"
                  :value="type.value"
                />

              </el-select>
            </el-form-item>
            <el-form-item prop="instrument_type" label="Instrument Type">
              <el-select v-model="form.instrument_type" clearable>
                <el-option
                  v-for="type in instrumentTypeList"
                  :key="type._id"
                  :label="type.name"
                  :value="type._id"
                />

              </el-select>
            </el-form-item>
            <el-form-item prop="select_monitor" label="Select CCTV For Alert">
              <el-select v-model="form.data.select_monitor" clearable>
                <el-option
                  v-for="monitor in projectMonitorList"
                  :key="monitor.content"
                  :label="monitor.name"
                  :value="monitor.content"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-row>
              <h4>Settings</h4>
            </el-row>
            <el-form-item>
              <el-checkbox v-model="form.data.allowZeroMeasurement" label="產生raw為0的數據" />
              <el-checkbox v-model="form.data.allowRate" label="使用速率計算" />
            </el-form-item>
            <hr/>
            <el-form-item>
              <el-tooltip content="啟用此功能，將禁用傳統算法">
                <el-checkbox v-model="form.useFunction" label="使用算式" />
              </el-tooltip>
            </el-form-item>
            <el-form-item :label="'算式：' + form.data.evalFunction">
              <el-button type="primary" @click="showDialog('InstrumentComputeFunctionPage')">
                開啟算式工具
              </el-button>
            </el-form-item>
          </el-row>
        </template>
      </el-card>
      <el-card v-if="showInstrumentDetailsForm">
        <el-button v-if="formType === 'create'"
          :loading="isLoading"
          @click="createNewInstrument"
          type="primary"
        >
          新增儀器
        </el-button>
        <template v-else>
          <el-button type="danger" :loading="isLoading" @click="deleteInstrument">
            刪除儀器
          </el-button>
          <el-button type="danger" :loading="isLoading" @click="disableInstrument">
            禁用儀器
          </el-button>
          <el-button type="warning" :loading="isLoading" @click="deleteOutput">
            刪除數據
          </el-button>
          <el-button type="success" :loading="isLoading" @click="generateOutput">
            生成測量值
          </el-button>
          <el-button type="primary" :loading="isLoading" @click="updateInstrumentVersion">
            更新版本
          </el-button>
          <el-button type="primary" :loading="isLoading" @click="createNewInstrumentVersion">
            新增版本
          </el-button>
        </template>
      </el-card>
    </el-form>
    <el-dialog :visible="dialogVisible" @open="openDialog" @close="closeDialog">
      <instrument-compute-function-page ref="instrumentComputeFunctionPage" class="instrument-compute-function-page" v-if="dialogActionType='InstrumentComputeFunctionPage'" :computeFunction="form.data.evalFunction"></instrument-compute-function-page>
    </el-dialog>
  </div>
</template>

<script>
import { chartTypeList } from '@/lib/base/chart';
import { coverObject } from '@/lib/base/helper';
import InstrumentComputeFunctionPage from './InstrumentComputeFunctionPage.vue';
export default {
  components: {
    InstrumentComputeFunctionPage,
  },  
  name: 'InstrumentForm',
  props: {
    formType: {
      type: String,
      default: 'create',
      required: true,
      validator: type => {
        return ['create', 'edit'].includes(type);
      },
    },
  },
  data() {
    return {
      componentKey: 0,
      isLoading: false,
      loadingDataIndex: false,
      loadingProject: false,
      loadingDocument: false,
      loadingInstrumentList: false,
      selectedInstrumentVersion: {},
      selectedInstrument: null,
      selectedBattInstrument: null,
      form: {
        project: null,
        instrument_type: null,
        display_output: 'reading2',
        chart_type: null,
        data: {
          name: '',
          unit: '',
          R0: 0,
          offset: 0,
          F0: 0,
          F1: 1,
          F2: 0,
          F3: 0,
          F4: 0,
          F5: 0,
          T0: 0,
          K: 0,
          WL_plus: '',
          WL_minus: '',
          AL_plus: '',
          AL_minus: '',
          WSL_plus: '',
          WSL_minus: '',
          alert_count_limit: 2,
          down_count_base: 1,
          down_count_limit: 2,
          startDate: '',
          reference: '',
          document_id: '',
          bind_batt: null,
          decimal_points: 2,
          created_at: new Date(),
          allowZeroMeasurement: true,
          coordinates: [null, null],

          allowRate: false,
          rate_1Hr_config:{
            WL_plus: '',
            WL_minus: '',
            AL_plus: '',
            AL_minus: '',
            WSL_plus: '',
            WSL_minus: '',
          },
          rate_24Hr_config:{
            WL_plus: '',
            WL_minus: '',
            AL_plus: '',
            AL_minus: '',
            WSL_plus: '',
            WSL_minus: '',
          },
          rate_alert_count_limit: 2,
          select_monitor: '',
          class: '',
          TRA_no: '',
          evalFunction: '',
        },
      },
      rules: {
        'form.name': {
          required: true,
        },
      },
      projectList: [],
      documentList: [],
      dataIndexList: [],
      instrumentTypeList: [],
      instrumentList: {},
      firstDataTimestamp: null,
      chartTypeList,

      project_id: '',
      projectMonitorList:[],

      dialogVisible: false,
      dialogActionType: '',
    };
  },
  computed: {
    selectedInstrumentDetails() {
      return this.instrumentList[this.form.project].find(instrument => {
        return instrument._id === this.selectedInstrument;
      });
    },
    showInstrumentDetailsForm() {
      if (this.formType === 'create') {
        return this.form.project;
      } else {
        return this.selectedInstrument;
      }
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    filteredBattInstruments() {
      return this.instrumentList[this.form.project].filter(instrument => 
        instrument.data[0].name.includes('Batt'),
      );
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    changeBind() {
      this.componentKey = this.componentKey === 0 ? 1 : 0;
    },
    resetFormData() {
      this.form.data = {
        name: '',
        unit: '',
        R0: 0,
        offset: 0,
        F0: 0,
        F1: 1,
        F2: 0,
        F3: 0,
        F4: 0,
        F5: 0,
        T0: 0,
        K: 0,
        WL_plus: '',
        WL_minus: '',
        AL_plus: '',
        AL_minus: '',
        WSL_plus: '',
        WSL_minus: '',
        alert_count_limit: 2,
        down_count_base: 1,
        down_count_limit: 2,
        startDate: '',
        reference: '',
        document_id: '',
        created_at: new Date(),
        decimal_points: 2,
        allowZeroMeasurement: true,
        coordinates: [null, null],

        allowRate: false,
        rate_1Hr_config:{
          WL_plus: '',
          WL_minus: '',
          AL_plus: '',
          AL_minus: '',
          WSL_plus: '',
          WSL_minus: '',
        },
        rate_24Hr_config:{
          WL_plus: '',
          WL_minus: '',
          AL_plus: '',
          AL_minus: '',
          WSL_plus: '',
          WSL_minus: '',
        },
        rate_alert_count_limit: 2,
        select_monitor: '',
        class: '',
        TRA_no: '',
        evalFunction: '',
      };
    },
    async deleteInstrument() {
      const response = await this.$confirmDelete('此操作將永久刪除該儀器');
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `instrument/${this.selectedInstrument}`,
        })
          .then(() => {
            this.$notifySuccess('刪除成功');
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async disableInstrument() {
      const response = await this.$confirmDelete('此操作將暫停儀器透過程式(data-processor)自動更新');
      if (response === 'confirm'){
        console.log('將暫停儀器透過程式(data-processor)自動更新');
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'post',
          apiName: `instrument/${this.selectedInstrument}/disable`,
        })
          .then(() => {
            this.$notifySuccess('操作成功');
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });

      }
    },
    async deleteOutput() {
      const response = await this.$confirmDelete('此操作將永久刪除該儀器所有的測量值');
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'delete',
          apiName: `instrument/${this.selectedInstrument}/output`,
        })
          .then(res => {
            const msg = `${res.data.deletedCount} 筆資料已成功刪除`;
            this.$notifySuccess('刪除成功', msg);
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async generateOutput() {
      const response = await this.$confirmCreate('此操作將刪除現有測量值並重建');
      if (response === 'confirm') {
        this.isLoading = true;
        return this.$axios({
          category: 'admin',
          method: 'post',
          apiName: `instrument/${this.selectedInstrument}/output`,
        })
          .then(res => {
            this.$notifySuccess(`已重置${res.data.createdCount}筆數據`);
          })
          .catch(this.$notifyError)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    initPage() {
      Promise.all([
        this.getProjectList(),
        this.getDocumentList(),
        this.getInstrumentTypeList(),
      ]).catch(this.$notifyError);
    },
    handleDocumentChange() {
      this.form.data.reference = null;
      Promise.all([this.getDataIndex(), this.getDocumentFirstDataTimestamp()]).catch(
        this.$notifyError,
      );
    },
    createNewInstrument() {
      this.isLoading = true;
      const { project, chart_type, instrument_output, display_output } = this.form;
      this.$axios({
        category: 'admin',
        method: 'post',
        apiName: 'instrument',
        data: {
          project,
          chart_type,
          instrument_output,
          display_output,
          data: { ...this.form.data },
        },
      })
        .then(() => {
          this.$notifySuccess('新增成功');
          this.getProjectInstrumentListWithVersion(true);
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    createNewInstrumentVersion() {
      this.isLoading = true;
      return this.$axios({
        category: 'admin',
        method: 'post',
        apiName: `instrument/${this.selectedInstrument}/version`,
        data: {
          data: { ...this.form.data },
        },
      })
        .then(res => {
          const newInstrumentDetails = res.data;
          const selectedProjectId = this.form.project;
          const instrumentReferIndex = this.instrumentList[selectedProjectId].findIndex(
            instrument => {
              return instrument._id === newInstrumentDetails._id;
            },
          );
          this.instrumentList[selectedProjectId][instrumentReferIndex] = newInstrumentDetails;
          this.instrumentList = { ...this.instrumentList };
          this.resetInstrumentVersion();
          this.$notifySuccess('版本新增成功');
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateInstrumentVersion() {
      this.isLoading = true;
      const { project, chart_type, instrument_output, display_output, instrument_type } = this.form;
      //console.log(this.form.data);
      return this.$axios({
        category: 'admin',
        method: 'put',
        apiName: `instrument/${this.selectedInstrument}/version/${this.selectedInstrumentVersion}`,
        data: {
          project,
          chart_type,
          instrument_output,
          display_output,
          instrument_type,
          data: { ...this.form.data },
        },
      })
        .then(() => {
          this.getProjectInstrumentListWithVersion(true);
          this.$notifySuccess('版本已更新成功');
        })
        .catch(this.$notifyError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDocumentList() {
      this.loadingDocument = true;
      return this.$store
        .dispatch('data/getDocumentList')
        .then(data => {
          this.documentList = data;
        })
        .finally(() => {
          this.loadingDocument = false;
        });
    },
    getProjectList() {
      this.loadingProject = true;
      return this.$store
        .dispatch('project/getProjectList')
        .then(res => {
          this.projectList = res.sort((a,b) => {
            if(a._id === this.selectedProject._id) return -1;
            else if(b._id === this.selectedProject._id) return 1;
            return a.start_date - b.start_date;
          });

          console.log(this.projectList);
          console.log(this.selectedProject);
        })
        .finally(() => {
          this.loadingProject = false;
        });
    },
    getInstrumentTypeList() {
      return this.$store.dispatch('data/getInstrumentTypeList').then(res => {
        console.log(res);
        this.instrumentTypeList = res;
      });
    },
    getDataIndex() {
      this.loadingDataIndex = true;
      return this.$store
        .dispatch('data/getDocumentDataIndex', {
          documentId: this.form.data.document_id,
        })
        .then(res => {
          this.dataIndexList = res;
        })
        .finally(() => {
          this.loadingDataIndex = false;
        });
    },
    getDocumentFirstDataTimestamp() {
      return this.$axios({
        category: 'admin',
        method: 'get',
        apiName: `document/${this.form.data.document_id}/firstDataTimestamp`,
      }).then(res => {
        if (res.data.timestamp) {
          const timestamp = res.data.timestamp;
          this.firstDataTimestamp = timestamp;
          if (!this.form.data.startDate) {
            this.form.data.startDate = timestamp;
          }
        }
      });
    },
    afterProjectChange(){
      this.loadingInstrumentList = true;
      this.getProjectId();
      this.getProjectMonitorImage().then(
        this.getProjectInstrumentListWithVersion(),
      );
    },
    getProjectId(){
      for(let project of this.projectList){
        if(project._id === this.form.project){
          this.project_id = project.project_id;
        }
      }
    },
    getProjectInstrumentListWithVersion(isUpdate = false) {
      const _temp = {
        selectedInstrument: this.selectedInstrument,
        selectedInstrumentVersion: this.selectedInstrumentVersion,
      };
      this.loadingInstrumentList = true;
      this.selectedInstrument = null;
      this.selectedInstrumentVersion = null;
      this.resetFormData();
      return this.$store
        .dispatch('data/getProjectInstrumentListWithVersion', {
          projectId: this.form.project,
          isUpdate,
        })
        .then(res => {
          this.instrumentList[this.form.project] = res;
          coverObject(this.form, res);
          this.selectedInstrument = _temp.selectedInstrument;
          this.selectedInstrumentVersion = _temp.selectedInstrumentVersion;
          this.resetInstrumentVersion();
        })
        .finally(() => {
          this.loadingInstrumentList = false;
        });
    },
    resetInstrumentVersion() {
      if (!this.selectedInstrument) return;
      const version = this.selectedInstrumentDetails.data[
        this.selectedInstrumentDetails.data.length - 1
      ];
      this.selectedInstrumentVersion = version._id;
      this.form.chart_type = this.selectedInstrumentDetails.chart_type;
      this.form.instrument_type = (this.selectedInstrumentDetails.instrument_type) ? this.selectedInstrumentDetails.instrument_type._id : null;
      this.resetFormData();
      coverObject(this.form.data, version);
      console.log('test~~');
      console.log(this.form.data);
      console.log(version);
      this.handleDocumentChange();
      this.form.data.reference = version.reference;
      this.form.data.bind_batt = version.bind_batt ? version.bind_batt : null;
      this.$forceUpdate();
    },
    changeInstrumentVersion(versionId) {
      const version = this.selectedInstrumentDetails.data.find(version => {
        return version._id === versionId;
      });
      this.form.chart_type = this.selectedInstrumentDetails.chart_type;
      this.form.instrument_type = (this.selectedInstrumentDetails.instrument_type) ? this.selectedInstrumentDetails.instrument_type._id : null;
      this.$refs.form.resetFields();
      this.resetFormData();
      coverObject(this.form.data, version);
      this.handleDocumentChange();
      this.form.data.reference = version.reference;
      this.form.data.bind_batt = version.bind_batt ? version.bind_batt : null;
      this.$forceUpdate();
    },
    async getProjectMonitorImage(){
      try{
        const res = await this.$axios({
          category: 'project',
          method: 'get',
          apiName: `${this.project_id}/getMonitorImage`,
          useUrl:`${process.env.VUE_APP_IMAGE_CCTV_URL}`,
        });
        let images = res.data.images;
        if(images){
          let undefined_count = 0;
          this.projectMonitorList = images.map(ele => {
            let name = ele.content.replace(/\s+/g, ' ');
            //console.log(`get content: ${content}`);
            if(name===' ' || name === '') name = 'undefined';
            return {
              name,
              content: ele.content,
            };
          }).map(ele=> {
            if(ele.name==='undefined'){
              ele.name = `undefined_${undefined_count}`;
              undefined_count++;
            }
            return ele;
          });
        }
      }
      catch(e){
        console.log(e);
        this.$notifyError();
      }
    },

    showDialog(type) {
      this.dialogVisible = true;
      this.dialogActionType = type;
    },
    openDialog() {
      setTimeout(() => {
        this.$refs.instrumentComputeFunctionPage.start();
      }, 100);
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    //showFunctionPage(){
    //  console.log('showFunctionPage');
    //  this.form.evalFunction = '=100';
    //  this.enableFunctionPage = true;
    //},
  },
};
</script>

<style lang="stylus">
.instrument-compute-function-page {
  height: 750px;
}
</style>